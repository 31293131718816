<template>
    <div class="w-full">
      <div class="flex flex-col">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="mx-auto py-5">
            <div class="grid grid-cols-5 md:grid-cols-6 gap-4 w-full items-center md:px-0">
              <div>
                <span class="p-input-icon-left w-full">
                  <i class="pi pi-search" />
                  <InputText
                    type="date"
                    v-model="search.created_at"
                    v-debounce:700ms="getJobs"
                    @change="getJobs"
                    class="p-inputtext-sm shadow-sm w-full"
                    autocomplete="off"
                  />
                </span>
              </div>
              <div>
                <span class="p-input-icon-left w-full">
                  <i class="pi pi-search" />
                  <MultiSelect
                    v-model="search.statesSelect"
                    :placeholder="$t('states')"
                    :options="states"
                    :filter="true"
                    class="w-full p-multiselect-icon leading-3"
                    optionValue="value"
                    optionLabel="label"
                    @change="getJobs"
                  />
                </span>
              </div>
    
              <div class="relative col-span-4 flex flex-row gap-5">
                <p
                  class="text-sm font-semibold text-slate-500 hover:text-slate-700 cursor-pointer"
                  @click="resetSearch"
                  v-if="search.created_at != '' || search.statesSelect.length > 0"
                >
                  {{ $t('clearFilters') }}
                </p>
    
                <div class="relative flex flex-1 self-end justify-end">
                  <div class="relative">
                    <Button @click="getJobs(true)" :label="$t('audience.segmentIntegrationJob.reloadJobs')" :loading="loadings.reloadJob" icon="pi pi-replay" iconPos="left" class="p-button-light shadow" />
                  </div>
                </div>
              </div>
    
            </div>
          </div>
          <BlockUI :blocked="loadings.general" :baseZIndex="100" :autoZIndex="false">
            <BlockUILoader v-if="loadings.general" :text="$t('wizard.validating')" />
    
            <div class="flex flex-col items-center mb-2 w-full">
              <PaginationSelect v-if="pagination.path != ''" :current_page="pagination.current_page" :from="pagination.from" :last_page="pagination.last_page" :path="pagination.path" :per_page="pagination.per_page" :to="pagination.to" :total="pagination.total" :dropdown="true" @change-page="paginationChange" />
            </div>
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.createDate") }}</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.state") }}</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.segmentIntegrationJob.date_start") }}</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.segmentIntegrationJob.total_profiles_sync") }}</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.segmentIntegrationJob.date_end") }}</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.segmentIntegrationJob.memory_peak_usage") }}</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900"></th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="(job, index) in jobs" :key="index">
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div class="font-medium text-gray-800 flex flex-row">{{ $moment(job.created_at).format("DD-MM-YYYY, hh:mm") || $t("audience.notData") }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div class="font-medium text-gray-800 flex flex-row">{{ $t(`integrations.job.states.${job.state}`) }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div class="font-medium text-gray-800 flex flex-row">{{ job.date_init_process ? $moment(job.date_init_process).format("DD-MM-YYYY, hh:mm") : $t("audience.notData") }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div class="font-medium text-gray-800 flex flex-row">{{ job.total_data_sync ? job.total_data_sync : $t("audience.notData") }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div class="font-medium text-gray-800 flex flex-row">{{ job.date_end_process ? $moment(job.date_end_process).format("DD-MM-YYYY, hh:mm") : $t("audience.notData") }}</div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div class="font-medium text-gray-800 flex flex-row">{{ job.memory_peak_usage ? job.memory_peak_usage +"MB" : $t("audience.notData") }}</div>
                    </td>
                    <td class="flex-center space-x-2 justify-end relative whitespace-nowrap p-4 text-sm font-medium">
                      <Button v-if="!job.kill && !['FAILURE', 'FINISHED'].includes(job.state)" @click="killJob(job.id, index)" :label="$t('audience.segmentIntegrationJob.kill')" icon="pi pi-times-circle" iconPos="left" class="p-button-sm p-button-danger shadow" />
                      <Tag v-else-if="job.kill" class="bg-red-100 text-red-700 font-normal text-[0.80rem]" :value="$t('audience.segmentIntegrationJob.killSuccess')" icon="pi pi-info-circle" />
                      <Tag v-else class="bg-gray-100 text-gray-700 font-normal text-[0.80rem]" :value="$t(`integrations.job.states.FINISHED`)" icon="pi pi-info-circle" />
                      <Button v-if="job.data_output.length" @click="showModalExamples(job)" :label="$t('integrations.job.examples')" icon="pi pi-times-circle" iconPos="left" class="p-button-sm p-button-info shadow" />
                      <Tag v-if="!job.data_output.length" class="bg-yellow-100 text-yellow-700 font-normal text-[0.80rem]" :value="$t('integrations.job.examplesNotFound')" icon="pi pi-info-circle" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </BlockUI>
        </div>
      </div>
    </div>
    <Dialog :header="$t('integrations.job.examples')" v-model:visible="modal.examples.show" :style="{width: '50vw'}" position="right" modal>
      <div class="bg-white border shadow-sm rounded-lg divide-y-2 divide-y-200 mb-3" v-for="(example, i) in modal.examples.examplesData" :key="i">
        <json-viewer :value="example.body" preview copyable class="md:ml-6 text-sm md:text-md text-gray-500 md:py-2 md:px-4">
          {{ example.body }}
        </json-viewer>
      </div>
    </Dialog>
</template>

<script>
  import axiosClient from "@/config/AxiosClient"
  import Button from "primevue/button"
  import PaginationSelect from "@/components/PaginationSelect"
  import BlockUI from "primevue/blockui"
  import BlockUILoader from "@/components/UI/BlockUILoader"
  import InputText from 'primevue/inputtext'
  import MultiSelect from 'primevue/multiselect';
  import Tag from "primevue/tag"
  import Dialog from "primevue/dialog"

  export default {
    name: "IntegrationJob",
    components: {
      Button,
      PaginationSelect,
      BlockUI,
      BlockUILoader,
      InputText,
      MultiSelect,
      Tag,
      Dialog
    },
    props: {
      integration: Object
    },
    data() {
      return {
        jobs: [],
        pagination: {
          current_page: 1,
          from: 1,
          to: 2,
          last_page: 0,
          per_page: 20,
          path: "",
          total: 0,
        },
        states: [],
        search: {
          created_at: '',
          statesSelect: []
        },
        loadings: {
          general: false,
          reloadJob: false
        },
        modal: {
          examples: {
            show: false,
            examplesData: []
          }
        }
      };
    },
    methods: {
      async getJobs(reload=false){
        this.loadings.general = true
        if(reload) this.loadings.reloadJob = true
        let params = {}
        if(this.search.created_at != ''){
          params.created_at = this.search.created_at
        }
        if(this.search.statesSelect.length){
          // make an explosion of the states and create a unique array
          params.state = this.search.statesSelect.reduce((acc, curr) => acc.concat(curr.split(", ")), []);
        }
        let response = await axiosClient.get(`api/2.0/integration/${this.integration.id}/jobs`, {params})
        this.jobs = response.data.data
        this.pagination = response.data.meta

        this.loadings.general = false
        if(reload) this.loadings.reloadJob = false
      },
      resetSearch(){
        this.search.text = ''
        this.search.date = ''
        this.search.channelSelect = []
        this.search.segmentSelect = []
        this.getJobs()
      },
      async killJob(integrationJobId, index){
        await axiosClient.get(`api/2.0/integration/jobs/${integrationJobId}/kill`)
        this.jobs[index].kill = true
        this.jobs[index].state = 'KILLED'
        this.$toast.success(this.$t("audience.segmentIntegrationJob.killSuccess"))
      },
      async paginationChange(page) {
        this.loadings.general = true
        this.pagination.current_page = page
        await this.getJobs()
      },
      async showModalExamples(job){
        if(!job.data_output) return

        this.modal.examples.examplesData = job.data_output
        this.modal.examples.show = true
      }
    },
    created() {
      this.states = [
        {label: this.$t('integrations.job.states.INIT'), value: 'INIT'},
        {label: this.$t('integrations.job.states.INSTANTIATING'), value: 'INSTANTIATING,DATA_FROM,AUTOMAPPER'},
        {label: this.$t('integrations.job.states.SEND_DATA'), value: 'SEND_DATA'},
        {label: this.$t('integrations.job.states.FINISHED'), value: 'FINISHED'},
        {label: this.$t('integrations.job.states.FAILURE'), value: 'FAILURE'},
      ]
      this.getJobs()
    }
  }
</script>