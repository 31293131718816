<script>
import { Icon } from "@iconify/vue"
import Button from "primevue/button"
import SplitButton from "primevue/splitbutton"
import Tag from "primevue/tag"
import Dialog from "primevue/dialog"
import Dropdown from "primevue/dropdown"
import LabelRequired from "@/components/LabelRequired"
import IntegrationJob from "@/components/Integrations/Job/IntegrationJob"
import Checkbox from "primevue/checkbox"
import axiosClient from "@/config/AxiosClient"

import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { mapGetters } from "vuex"

const sprintf = require("sprintf-js").sprintf

export default {
  props: {
    projectConnection: Object
  },

  emits: ["refresh", "moveIntegration"],

  components: {
    Icon,
    Button,
    SplitButton,
    Tag,
    Dialog,
    Dropdown,
    LabelRequired,
    IntegrationJob,
    Checkbox
  },

  data() {
    return {
      form: new FormClient({}),
      modal: {
        detail: {
          show: false,
          integrations: []
        },
        sync: {
          show: false,
          id: null,
          subtractDates: 1,
          subtractDatesOptions: [
            {
              label: this.$t("integrations.automapper.1month"),
              subtractNumber: 1
            },
            {
              label: this.$t("integrations.automapper.3months"),
              subtractNumber: 3
            },
            {
              label: this.$t("integrations.automapper.6months"),
              subtractNumber: 6
            },
            {
              label: this.$t("integrations.automapper.12months"),
              subtractNumber: 12
            }
          ],
          form: new FormClient({
            dateInit: null,
            dateEnd: null
          })
        },
        job: {
          show: false,
          integration: {}
        },
        moveIntegration: {
          show: false,
          project_id: null,
          deleteCredentials: true
        }
      },
      menu: [
        {
          label: this.$t("Details"),
          icon: "pi pi-file",
          command: () => {
            this.modal.detail.integrations = [...this.projectConnection.integrations]
            this.modal.detail.integrations.map((integration) => {
              integration.logs = []
              integration.formLog = new FormClient({})
              integration.formRun = new FormClient({})
              return integration
            })

            this.modal.detail.show = true
          },
          permissions: ["v2.client.integration.run", "v2.client.integration.syncAt"]
        },
        {
          label: this.projectConnection.is_active ? this.$t("Desactivate") : this.$t("Activate"),
          icon: "pi pi-power-off",
          command: () => {
            this.$confirm.require({
              group: "confirm",
              message: this.projectConnection.is_active ? this.$t("You will desactivate the integration.") : this.$t("You will activate the integration."),
              header: this.$t("Are you sure?"),
              icon: "pi pi-info-circle",
              acceptClass: "p-button-primary",
              acceptLabel: this.$t("yesContinue"),
              rejectLabel: this.$t("cancel"),
              accept: async () => {
                await this.form.put(sprintf(ApiRoute.integration.toggleActivation, { project_connection_id: this.projectConnection.id }))
                this.$emit("refresh")
              }
            })
          },
          permissions: ["v2.client.project_connection.activateOrDeactivate"]
        },
        {
          label: "Move integration",
          icon: "pi pi-arrow-right",
          command: () => {
            this.modal.moveIntegration.show = true
          },
          permissions: ['v2.project.connection.moveIntegration']
        },
        {
          separator: true,
          permissions: ['v2.client.project_connection.delete']
        },
        {
          label: this.$t("Delete"),
          icon: "pi pi-trash",
          command: () => {
            this.$confirm.require({
              group: "confirm",
              message: this.$t("You won't be able to revert this!"),
              header: this.$t("Are you sure?"),
              icon: "pi pi-exclamation-triangle",
              acceptClass: "p-button-danger",
              acceptLabel: this.$t("yesDelete"),
              rejectLabel: this.$t("cancel"),
              accept: async () => {
                await this.form.delete(sprintf(ApiRoute.integration.delete, { project_connection_id: this.projectConnection.id }))
                this.$emit("refresh")
              }
            })
          },
          permissions: ["v2.client.project_connection.delete"]
        }
      ]
    }
  },

  computed: {
    ...mapGetters({ permissions: "Projects/permissionsForProject" }),
    hasError() {
      return this.projectConnection.integrations.some((integration) => {
        return integration.is_error
      })
    },
    getProjects() {
      return this.$store.state.Projects.projects.filter(project => project.id != this.$route.params.id)
    }
  },

  methods: {
    countServices(connection) {
      let servicesActives = 0
      if (connection.integrations.length > 0 && connection.is_active ) {
        servicesActives = connection.integrations.filter((x) => x.is_active === true).length
      }
      return servicesActives
    },
    async logs(id) {
      const index = this.modal.detail.integrations.findIndex((integration) => integration.id == id)
      this.modal.detail.integrations[index].logs = []

      const response = await this.modal.detail.integrations[index].formLog.get(sprintf(ApiRoute.integration.logs, { integration_id: id }))
      this.modal.detail.integrations[index].logs = response.data.data
    },

    closeLogs(id) {
      const index = this.modal.detail.integrations.findIndex((integration) => integration.id == id)
      this.modal.detail.integrations[index].logs = []
    },

    syncModal(id) {
      this.modal.sync.show = true
      this.modal.sync.form.id = id
    },

    syncSubmit() {
      this.modal.sync.form.dateInit = this.$moment().subtract(this.modal.sync.subtractDates, "month").format("YYYY-MM-DD 00:00:00")
      this.modal.sync.form.dateEnd = this.$moment().format("YYYY-MM-DD 23:59:59")
      this.modal.sync.form.post(sprintf(ApiRoute.integration.sync, { integration_id: this.modal.sync.form.id }))
    },

    run(id) {
      this.$confirm.require({
        group: "confirm",
        message: this.$t("You will run the sync now."),
        header: this.$t("Are you sure?"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-primary",
        acceptLabel: this.$t("yesContinue"),
        rejectLabel: this.$t("cancel"),
        accept: () => {
          const index = this.modal.detail.integrations.findIndex((integration) => integration.id == id)
          this.modal.detail.integrations[index].formRun.get(sprintf(ApiRoute.integration.execute, { integration_id: id }))
        }
      })
    },
    jobs(integration) {
      this.modal.job.integration = integration
      this.modal.job.show = true
    },
    async moveIntegration() {
      if (this.modal.moveIntegration.project_id) {
        this.modal.moveIntegration.loading = true
        let deleteCredentials = this.modal.moveIntegration.deleteCredentials ? 1 : 0
        await axiosClient.get(`api/2.0/project/connection/${this.projectConnection.id}/move/${this.modal.moveIntegration.project_id}?deleteCredentials=${deleteCredentials}`)
        this.$emit("moveIntegration")
        this.modal.moveIntegration.loading = false
        this.modal.moveIntegration.show = false
        this.$toast.success(this.$t("integrations.moveIntegrationSuccess"))
      }
    }
  }
}
</script>

<template>
  <div class="block hover:bg-gray-50" :class="[form.busy ? 'opacity-20' : '']">
    <div class="px-4 py-4 sm:px-6 relative">
      <div class="grid grid-flow-dense grid-cols-2 items-center md:space-x-10"
           :class="{'md:grid-cols-7': menu.filter(f => (f.permissions && $verifyPermission(permissions, f.permissions) == true) || !f.permissions).length > 1, 'md:grid-cols-6': menu.filter(f => (f.permissions && $verifyPermission(permissions, f.permissions) == true) || !f.permissions).length <= 1}">
        <!-- Origin to Target -->
        <div class="flex-center space-x-3 col-span-2">
          <img :src="projectConnection.project_credential_from.channel.avatar_short" class="w-10 h-10 object-contain" />
          <div class="whitespace-nowrap">
            <span class="flex items-center text-base text-gray-500 min-w-[5rem]">{{ projectConnection.project_credential_from.channel.name }}</span>
          </div>
          <Icon icon="tabler:link" class="w-6 h-6 text-gray-400" />
          <img :src="projectConnection.project_credential_to.channel.avatar_short" class="w-10 h-10 object-contain" />
          <div class="whitespace-nowrap">
            <span class="flex items-center text-base text-gray-500">{{ projectConnection.project_credential_to.channel.name }}</span>
          </div>
        </div>

        <!-- Extras -->
        <div class="flex-center space-x-3 flex-shrink-0">
          <div v-if="projectConnection.extras?.isAutomatic">
            <Tag class="bg-indigo-100 text-indigo-700 font-normal" :value="$t('Automatic')" icon="pi pi-info-circle"
                 v-tooltip.top="$t('This integration was created automatically so you can easily manage your abandoned carts with MailUp.')" />
          </div>
          <div v-if="hasError">
            <Tag class="bg-red-100 text-red-700 font-normal" :value="$t('Error')" icon="pi pi-times-circle"
                 v-tooltip.top="$t('This integration has errors, please check your logs and check the connection.')" />
          </div>
        </div>

        <!-- Status -->
        <div class="flex flex-col flex-shrink-0">
          <div class="flex-center space-x-2">
            <Icon icon="tabler:activity-heartbeat" class="w-4 h-4 text-gray-400" />
            <span class="text-sm text-gray-400">{{ $t("status") }}</span>
          </div>
          <div>
            <span v-if="projectConnection.is_active"
                  class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">{{ $t("integrations.active")
              }}</span>
            <span v-if="!projectConnection.is_active"
                  class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">{{ $t("integrations.disable")
              }}</span>
          </div>
        </div>

        <!-- Services -->
        <div class="flex flex-col flex-shrink-0">
          <div class="flex-center space-x-2">
            <Icon icon="tabler:plug-connected" class="w-4 h-4 text-gray-400" />
            <span class="text-sm text-gray-400">{{ $t("services") }}</span>
          </div>
          <div>
            <span
              class="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">{{ projectConnection?.integrations ? countServices(projectConnection) : 0
              }} Activos</span>
          </div>
        </div>

        <!-- Creation date -->
        <div class="flex flex-col flex-shrink-0">
          <div class="flex-center space-x-2">
            <Icon icon="tabler:calendar-event" class="w-4 h-4 text-gray-400" />
            <span class="text-sm text-gray-400">{{ $t("lastEdited") }}</span>
          </div>
          <div class="flex items-center text-sm text-gray-800" v.tooltip.top="Creado">
            {{ $moment(projectConnection.updated_at).format("YYYY-MM-DD") }}
          </div>
        </div>

        <!-- Actions -->
        <div
          v-if="menu.filter(f => (f.permissions && $verifyPermission(permissions, f.permissions) == true) || !f.permissions).length > 1"
          class="flex-center justify-end space-x-3">
          <SplitButton
            :model="menu.filter(f => (f.permissions && $verifyPermission(permissions, f.permissions) == true) || !f.permissions)"
            class="p-button-secondary">
            <router-link v-if="$verifyPermission(permissions, ['v2.client.integration.update.automapper'])"
                         :to="{ name: 'Form Integration', params: { id: $route.params.id, projectConnectionId: projectConnection.id } }">
              <Button :label="$t('edit')" type="button" icon="pi pi-pencil" iconPos="left" class="p-button-secondary" />
            </router-link>
            <Button v-else label="opciones" type="button" class="p-button-secondary cursor-default" />
          </SplitButton>
        </div>
      </div>
    </div>
  </div>

  <!-- Dialog for show details -->
  <Dialog :header="$t('Details')" v-model:visible="modal.detail.show" modal :dismissableMask="true"
          :style="{ width: '60rem', padding: 0 }">
    <div class="p-2">
      <div v-for="integration of modal.detail.integrations" :key="integration.id"
           class="bg-[#F1F5F9] p-3 flex flex-col justify-between items-center rounded-md mb-2.5">
        <div class="w-full flex flex-row justify-between items-center">
          <div class="flex flex-row justify-start items-center font-semibold text-gray-600 relative">
            <!-- Alert if the service has an error -->
            <div v-if="integration.is_error"
                 class="bg-red-500 text-white text-sm font-light -ml-5 px-2 py-0 rounded-md absolute shadow"
                 v-tooltip.top="$t('Check the logs')">!
            </div>

            <!-- Service icon -->
            <Icon v-if="integration.service.name == 'Customers'" icon="tabler:users"
                  class="w-6 h-6 ml-2 text-gray-400" />
            <Icon v-if="integration.service.name == 'Contacts'" icon="tabler:users"
                  class="w-6 h-6 ml-2 text-gray-400" />
            <Icon v-if="integration.service.name == 'Orders'" icon="tabler:report-money"
                  class="w-6 h-6 ml-2 text-gray-400" />
            <Icon v-if="integration.service.name == 'Newsletter'" icon="tabler:mail"
                  class="w-6 h-6 ml-2 text-gray-400" />
            <Icon v-if="integration.service.name == 'Products'" icon="tabler:list-details"
                  class="w-6 h-6 ml-2 text-gray-400" />
            <Icon v-if="integration.service.name == 'Carts'" icon="tabler:shopping-cart"
                  class="w-6 h-6 ml-2 text-gray-400" />
            <Icon v-if="integration.service.name == 'Tickets'" icon="tabler:ticket"
                  class="w-6 h-6 ml-2 text-gray-400" />

            <!-- Service name -->
            <p class="text-gray-600 text-sm font-semibold ml-3">{{ integration.service.name }}</p>
          </div>
          <div class="flex-center space-x-3">
            <Button v-if="integration.is_error" @click="logs(integration.id)" :loading="integration.formLog.busy"
                    type="button" class="p-button-secondary" v-tooltip.top="$t('Logs')">
              <Icon icon="tabler:align-box-left-middle" class="w-5 h-5" />
            </Button>
            <Button v-if="$verifyFeature($store.state.Projects.activeProject, 'segment_integration_job') && $verifyPermission(permissions, ['v2.client.integration.jobs'])" @click="jobs(integration)" type="button" class="p-button-secondary">
              {{ $t('integrations.job.title') }}
            </Button>
            <Button @click="syncModal(integration.id)" type="button" class="p-button-secondary"
                    v-tooltip.top="$t('Synchronize')">
              <Icon icon="tabler:refresh-dot" class="w-5 h-5" />
            </Button>
            <Button @click="run(integration.id)" :loading="integration.formRun.busy" type="button"
                    class="p-button-secondary" v-tooltip.top="$t('Run')">
              <Icon icon="tabler:play" class="w-5 h-5" />
            </Button>
          </div>
        </div>

        <!-- List service config -->
        <div v-if="integration.logs.length" class="w-full pt-3 mt-2 border-t">
          <div class="flex justify-end">
            <Button @click="closeLogs(integration.id)" :label="$t('Close')" type="button"
                    class="p-button-light p-button-sm my-4" />
          </div>
          <div v-for="log of integration.logs" :key="log.id">
            <div class="bg-gray-800 text-white border rounded-md p-4 text-xs relative my-4">
              <Tag class="bg-yellow-100 text-yellow-700 font-normal absolute -top-5 left-2"
                   :value="`${$t('Executed')} ${$moment(log.created_at).format('YYYY-MM-DD HH:mm:ss')}`" />
              <code>{{ log.message }}</code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>

  <Dialog :header="$t('integrations.job.title')" v-model:visible="modal.job.show" modal :dismissableMask="true"
          :style="{ width: '80vw', padding: 0 }">
    <IntegrationJob :integration="modal.job.integration"></IntegrationJob>
  </Dialog>

  <!-- Dialog for show sync -->
  <Dialog :header="$t('Synchronize')" v-model:visible="modal.sync.show" modal :dismissableMask="true"
          :style="{ width: '30rem', padding: 0 }">
    <form @submit.prevent="syncSubmit" @keydown="form.onKeydown($event)">
      <!-- Subtract dates field -->
      <div class="form-group">
        <div class="flex-center justify-between">
          <div class="text-gray-600 text-sm font-normal">{{ $t("integrations.automapper.sync") }}
            <LabelRequired />
          </div>
          <Dropdown v-model="modal.sync.subtractDates" :options="modal.sync.subtractDatesOptions" optionLabel="label"
                    optionValue="subtractNumber" dataKey="subtractNumber" :placeholder="$t('select')"
                    class="p-inputtext-sm shadow-sm w-52" />
        </div>
      </div>
      <div class="flex-center justify-end space-x-3 mt-5">
        <p v-if="modal.sync.form.recentlySuccessful">{{ $t("Executed") }}.</p>
        <Button type="submit" :loading="modal.sync.form.busy" :label="$t('Synchronize')" icon="pi pi-arrow-right"
                iconPos="right" class="p-button-primary shadow-md" />
      </div>
    </form>
  </Dialog>
  <!-- Dialog for integration -->
  <Dialog :header="$t('integrations.moveIntegration')" v-model:visible="modal.moveIntegration.show" modal
          :dismissableMask="true" :style="{ width: '30rem', padding: 0 }">
    <!-- Subtract dates field -->
    <div class="form-group">
      <div class="flex-center justify-between">
        <div class="text-gray-600 text-sm font-normal">{{ $t("projects.projects") }}
          <LabelRequired />
        </div>
        <Dropdown v-model="modal.moveIntegration.project_id" :options="getProjects" optionLabel="company"
                  optionValue="id" dataKey="id" :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-52" />
      </div>
      <div class="flex-center content-center mt-4">
        <label for="deleteCredentials" class="text-gray-600 text-sm font-normal mr-2">
          {{ $t("Eliminar credenciales utilizadas en esta integración") }} </label>
        <Checkbox :binary="true" v-model="modal.moveIntegration.deleteCredentials" inputId="deleteCredentials"
                  name="deleteCredentials" :value="true" />
      </div>
    </div>
    <div class="flex-center justify-end space-x-3 mt-5">
      <Button @click="moveIntegration()" :loading="modal.moveIntegration.loading"
              :label="$t('integrations.moveIntegration')" icon="pi pi-arrow-right" iconPos="right"
              class="p-button-primary shadow-md" />
    </div>
  </Dialog>
</template>
