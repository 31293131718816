<template>
  <div>
    <SubHeader>
      <template #title>
        <Title :text="$t('integrations.title')" />
      </template>
      <template v-if="$verifyPermission(permissions, ['v2.client.integration.update.automapper'])" #actions>
        <Button @click="$router.push({ name: 'Form Integration', params: { id: $route.params.id } })"
                :label="$t('integrations.buttonWrapper')" icon="pi pi-plus" iconPos="right" type="button"
                class="p-button-success shadow" />
      </template>
    </SubHeader>

    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <!-- Skeleton -->
      <div v-if="loading">
        <Skeleton />
      </div>
      <!-- Integrations -->
      <div v-else>
        <div v-if="!projectConnections.length">
          <!-- Empty state -->
          <EmptyState />
        </div>
        <div v-else>
          <!-- List -->
          <div class="flex flex-col space-y-3">
            <div class="grid grid-cols-2 gap-5 sm:grid-cols-6">
              <div>
                <Stats :title="$t('totalConnections')" :value="projectConnections.length" />
              </div>
              <div>
                <Stats :title="$t('servicesActives')" :value="servicesActives" />
              </div>
            </div>

            <IntegrationList>
              <IntegrationItem v-for="(projectConnection, index) of projectConnections" :key="projectConnection.id"
                               :projectConnection="projectConnection" @refresh="onRefresh"
                               @moveIntegration="moveIntegration(projectConnection, index)" />
            </IntegrationList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
import SubHeader from "@/components/App/SubHeader"
import Title from "@/components/App/Title"
import Stats from "@/components/App/Stats"
import axiosClient from "@/config/AxiosClient"
import Skeleton from "@/components/Integrations/List/Skeleton"
import EmptyState from "@/components/Integrations/List/EmptyState"
import IntegrationList from "@/components/Integrations/List/IntegrationList"
import IntegrationItem from "@/components/Integrations/List/IntegrationItem"
import { mapGetters } from "vuex"

export default {
  name: `Integrations`,
  components: {
    Button,
    Skeleton,
    SubHeader,
    Stats,
    Title,
    EmptyState,
    IntegrationList,
    IntegrationItem
  },
  computed: {
    ...mapGetters({ permissions: "Projects/permissionsForProject" }),
    getProjects() {
      return this.$store.state.Projects.projects
    }
  },

  data() {
    return {
      projectConnections: [],
      pagination: {},
      loading: false,
      servicesActives: 0
    }
  },

  mounted() {
    this.searchProjectConnections()
    if (this.getProjects.length == 0) {
      this.$store.dispatch("Projects/getProjects", { filter: {} })
    }
  },

  methods: {
    async searchProjectConnections() {
      this.loading = true
      let params = {}
      params["filter[project_credential_from.project_id][=]"] = this.$route.params.id
      params["filter[project_credential_from_id][!=]"] = "null"
      params["sort"] = "-id"
      params["include"] = "project_credential_from.channel,project_credential_to.channel"

      let res = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/connection`, { params })

      this.projectConnections = res.data.data
      this.countStatistics(this.projectConnections)
      this.pagination = res.data.meta
      this.loading = false
    },

    countStatistics(connections) {
      let servicesActives = 0
      connections.forEach((element) => {
        if(element.is_active){
          servicesActives += element.integrations.filter((x) => x.is_active === true).length
        }
      })
      this.servicesActives = servicesActives
    },
    onRefresh() {
      this.searchProjectConnections()
    },
    moveIntegration(projectConnection, index) {
      this.projectConnections.splice(index, 1)
      let automaticIndex = this.projectConnections.findIndex(projectConnectionAutomatic => projectConnectionAutomatic.extras && projectConnectionAutomatic.extras?.project_connection_id == projectConnection.id)
      if (automaticIndex != -1) {
        this.projectConnections.splice(automaticIndex, 1)
      }
    }
  }
}
</script>
